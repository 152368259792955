<template>
    <div>
        <div class="step-content">
            <div class="step-content-header">
                <span class="big">创建单元</span>
            </div>
            <div class="line-form">
                <div class="line-left">单元名称</div>
                <div class="line-right">
                    <el-input type="text" v-model="ruleForm.unit_name" style="width: 490px"
                              maxlength="50" show-word-limit placeholder="请输入1~50个字"></el-input>
                </div>
            </div>
            <div class="line-form">
                <div class="line-left"></div>
                <div class="line-right">
                    <div class="text">通投出价（&yen;）</div>
                    <el-input-number class="number-input" :value="ruleForm.cast_fee" :precision="2" @change="changeCastFee"
                                     :controls="false" :min="0.01" :max="9999.99" style="width: 120px; margin-right: 10px"></el-input-number>
                </div>
            </div>
        </div>
        <div class="step-content">
            <div class="line-form" style="margin-top: 0">
                <div class="line-left">资源位</div>
                <div class="line-right">
                    <el-button size="medium" class="btn-red-line" @click="addResourceBtn">添加资源位</el-button>
                    <el-button size="medium" class="btn-red-line" :disabled="ruleForm.resources_data.length === 0" @click="delResourceBtn">删除</el-button>
                </div>
            </div>
            <div class="line-form" style="margin-top: 30px">
                <div class="line-left"></div>
                <div class="line-right">
                    <el-table :data="ruleForm.resources_data" border style="width: 100%;" @selection-change="selectChange"
                              :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                              :header-cell-style="{fontWeight: 'normal', color: '#14141C', background: '#F5F5F5',height: '60px'}">
                        <el-table-column type="selection" align="center" width="50"></el-table-column>
                        <el-table-column label="资源位" width="200px">
                            <template slot-scope="scope">
                                <div class="resource-box">
                                    <span class="text-overflow-2">{{scope.row.name}}</span>
                                    <img :src="scope.row.type === 0 ? require('../../../../assets/images/student/infoPrompt/dibutonglan.png') :
                                        scope.row.type === 1 ? require('../../../../assets/images/student/infoPrompt/dibutonglan.png') :
                                        scope.row.type === 2 ? require('../../../../assets/images/student/infoPrompt/Mydibutonglan.png') :
                                        scope.row.type === 3 ? require('../../../../assets/images/student/infoPrompt/PcRight.png') :
                                        scope.row.type === 4 ? require('../../../../assets/images/student/infoPrompt/PcIndex.png')  :
                                        require('../../../../assets/images/student/infoPrompt/Mydibutonglan.png')" alt="">
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="customers" label="潜在买家数量"></el-table-column>
                        <el-table-column prop="low" label="最低出价"></el-table-column>
                        <el-table-column prop="avg" label="平均出价"></el-table-column>
                        <el-table-column label="竞争热度" width="150px">
                            <template slot-scope="scope">
                                <el-rate v-model="scope.row.hot" disabled></el-rate>
                            </template>
                        </el-table-column>
                        <el-table-column prop="conversion_rate" label="转化率（%）"></el-table-column>
                        <el-table-column prop="click_rate" label="点击率（%）"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="step-content">
            <div class="line-form" style="margin-top: 0">
                <div class="line-left">定向设置</div>
                <div class="line-right">
                    整体曝光人数预估
                    <el-popover placement="top-start" width="200" trigger="click" content="预估启用人群第二天可能带来的最大去重曝光访客数，预估值仅供参考，实际数据还会受到出价、投放时段等因素的影响。特殊标明的人群、默认人群和智能定向人群不参与此处的总体评估。">
                        <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                    </el-popover>
                    {{exposurePeople}} 整体曝光量预估
                    <el-popover placement="top-start" width="200" trigger="click" content="预估启用人群第二天可能带来的最大去重曝光量，预估值仅供参考，实际数据还会受到出价、投放时段等因素的影响。特殊标明的人群、默认人群和智能定向人群不参与此处的总体评估。">
                        <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                    </el-popover>
                    {{exposureNumber}}
                </div>
            </div>
            <div class="line-form" style="margin-top: 16px">
                <div class="line-left"></div>
                <div class="line-right">
                    <div class="directional-set-wrapper">
                        <div class="line-item">
                            地域设置
                            <el-popover placement="top-start" width="200" trigger="click" content="地域设置可以帮助您实现特定区域定向，不同的地域设置会影响最终的【整体曝光人数预估】和【整体曝光量预估】。">
                                <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                            </el-popover>
                            <el-radio-group v-model="ruleForm.location_type" @change="toggleLocation" class="radioGroup-red" style="margin-right: 20px">
                                <el-radio :label="1">不限</el-radio>
                                <el-radio :label="2" @click.native="openAreaDrawer">特定区域</el-radio>
                            </el-radio-group>
                            已选择 <span class="red">{{ruleForm.locationNumber}}</span> 个区域
                        </div>
                        <div class="line-item">
                            人群定向
                            <el-popover placement="top-start" width="200" trigger="click" content="广告可按照特定的人群的覆盖范围投放并设置溢价。">
                                <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                            </el-popover>
                            已选人群 <span class="red">{{selectPeople}}</span>/6
                        </div>

                        <div class="directional-header-table">定向推荐人群</div>
                        <el-table :data="ruleForm.people_data" border style="width: 100%;"
                                  :cell-style="{height: '50px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                                  :header-cell-style="{fontWeight: 'normal', color: '#14141C', background: '#F5F5F5',height: '50px'}">
                            <el-table-column label="溢价人群" prop="name"></el-table-column>
                            <el-table-column label="是否启动人群">
                                <template slot-scope="scope">
                                    <el-switch :value="scope.row.switchPeople" @change="changeSwitchPeople($event, scope.row, scope.$index)" active-color="#20D08C" inactive-color="#DFDFDF"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="溢价系数" prop="premium" width="130">
                                <template slot-scope="scope">
                                    <el-input-number class="number-input" v-model="scope.row.percent" :precision="0"
                                                     @change="changePercent(scope.row, scope.$index)" :disabled="!scope.row.switchPeople"
                                                     :controls="false" :min="30" :max="500" style="width: 90px;"></el-input-number>
                                    <span style="margin-left: 4px">%</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="转化率（%）">
                                <template slot-scope="scope">
                                    {{scope.row.conversion !== null ? scope.row.conversion : '-'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="点击率（%）">
                                <template slot-scope="scope">
                                    {{scope.row.click !== null ? scope.row.click : '-'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="曝光人数预估">
                                <template slot-scope="scope">
                                    {{scope.row.show_people !== null ? scope.row.show_people : '-'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="曝光量预估">
                                <template slot-scope="scope">
                                    {{scope.row.show_total !== null ? scope.row.show_total : '-'}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-left: 30px">
            <el-button class="btn-w-140 btn-red-line" @click="goBack">取消</el-button>
            <el-button class="btn-w-140 btn-red" @click="toNext">确定</el-button>
        </div>

<!--        添加资源位-->
        <AddResourceDrawer :ruleForm.sync="ruleForm" ref="addResourceDrawer" />

<!--        地区设置-->
        <AreaSetDrawer :ruleForm.sync="ruleForm" ref="areaSetDrawer" />
    </div>
</template>

<script>
    import { StuOpProGetPeopleData, StuOpProSavePlan, StuOpProInfo,StuOpProPeopleData,StuOpProResourcesAndPeopleList,StuOpProGetLocation } from '@/utils/apis.js'
    import AddResourceDrawer from '@/components/student/exam/infographicPromotion/AddResourceDrawer'
    import AreaSetDrawer from '@/components/student/exam/infographicPromotion/AreaSetDrawer'
    export default {
        components: {
            AddResourceDrawer,
            AreaSetDrawer,
        },
       
        data() {
            return {
                ruleForm: {
                    unit_name: '', // 单元名称
                    cast_fee: void 0, // 通投出价
                    resources_data: [], // 资源位置信息
                    people_data: [ // 人群定向信息
                        {
                            type: 0, // 返回的数据中的type
                            percent: 100, // 溢价系数
                            click: null, // 点击率
                            conversion: null, // 转化率
                            show_people: null, // 曝光人数
                            show_total: null, // 曝光量预估
                            name: '核心人群', // 名字
                            switchPeople: false, // 是否启动人群
                        },
                        {
                            type: 1,
                            percent: 100,
                            click: null,
                            conversion: null,
                            show_people: null,
                            show_total: null,
                            name: '认知人群',
                            switchPeople: false,
                        },
                        {
                            type: 2,
                            percent: 100,
                            click: null,
                            conversion: null,
                            show_people: null,
                            show_total: null,
                            name: '意向人群',
                            switchPeople: false,
                        },
                        {
                            type: 3,
                            percent: 100,
                            click: null,
                            conversion: null,
                            show_people: null,
                            show_total: null,
                            name: '竞品人群',
                            switchPeople: false,
                        },
                        {
                            type: 4,
                            percent: 100,
                            click: null,
                            conversion: null,
                            show_people: null,
                            show_total: null,
                            name: '店铺人群',
                            switchPeople: false,
                        },
                        {
                            type: 5,
                            percent: 100,
                            click: null,
                            conversion: null,
                            show_people: null,
                            show_total: null,
                            name: '类目相关人群',
                            switchPeople: false,
                        },
                    ],
                    position_data: [], // 位置信息
                    id: this.$route.query.id, // 编辑保存，列表给的id
                    location_type: 1, // 地区选择1、不限 2、特定区域
                    locationNumber:0, // 已选地区数量
                },
                selectArr: [], // 资源位的表格多选list,
                infoStepTwoRuleForm:{},//创建计划里面的计划设置数据，包括名称，计费方式，每日预算，时段设置，投放策略等等,
                areaValue: [],//全部地区信息

            }
        },
       
        computed: {
            // 已选人群
            selectPeople() {
                let hasNum = this.ruleForm.people_data.filter(item => {
                    return item.switchPeople
                })
                return hasNum.length
            },
            // 整体曝光人数预估
            exposurePeople() {
                let total = 0
                this.ruleForm.people_data.forEach(item => {
                    if (item.show_people) {
                        total += item.show_people
                    } else {
                        total += 0
                    }
                })
                return total
            },
            // 整体曝光量预估
            exposureNumber() {
                let total = 0
                this.ruleForm.people_data.forEach(item => {
                    if (item.show_total) {
                        total += item.show_total
                    } else {
                        total += 0
                    }
                })
                return total
            }
        },
        activated(){ 
            // 每次进入页面都需要
            
            if (localStorage.getItem('infoStepTwo')) {
                this.infoStepTwoRuleForm = JSON.parse(localStorage.getItem('infoStepTwo'))
                 // 添加监听器 当自定义时间段改变的时候，需要重新调用一下添加资源位的数据和定位人群列表数据
                 const unwatch = this.$watch('infoStepTwoRuleForm.time_frame_data', {
                        deep: true,
                        handler: function(newVal, oldVal) {
                            let ischange=false
                        newVal.forEach((newObj, index) => {
                            if(!ischange){
                                let oldObj = oldVal[index];
                            for (let key in newObj) {
                                if (newObj[key] !== oldObj[key] && !ischange) {
                                    ischange=true
                                    this.$refs.addResourceDrawer.getResourceData();
                                    unwatch(); // 取消观察器
                                    return;
                                }
                 
                            }
                            }
                        });
    }
});
            }
            // 重新覆盖资源位和定向人群
            this.changeStuOpProResourcesAndPeopleList()
           
                 
            
        },
        mounted() {
            if (this.$route.query.editId) {
                this.getInfo()
            }  
             this.getAreaList()          
        },
        methods: {
             // 获取地区列表
             getAreaList() {
                StuOpProGetLocation().then((res) => {
                    this.areaList = res.data
                    this.areaValue = this.areaList.reduce((current, next) => {
                        const children = next.children.map(item => {
                            return [next.code, item.code];
                        });
                        return [...current, ...children];
                    }, [])
                    this.ruleForm.locationNumber=this.areaValue.length
                }).catch((err) => {})
            },
            // 获取编辑信息
            getInfo() {
                StuOpProInfo(this.$route.query.editId).then((res) => {
                    let positionData = res.data.position_data
                    let locationNumber = positionData.reduce((current, next) => {
                        const children = next.children.map(item => {
                            return [next.code, item.code];
                        });
                        return [...current, ...children];
                    }, [])
                    this.ruleForm = {
                        unit_name: res.data.unit_name,
                        cast_fee: res.data.cast_fee,
                        resources_data: res.data.resources_data,
                        people_data: res.data.people_data,
                        position_data: res.data.position_data || [],
                        id: res.data.id,
                        location_type: res.data.location_type,
                        locationNumber: res.data.location_type === 1 ? this.areaValue.length : locationNumber.length,

                        title: res.data.title,
                        budget: res.data.budget,
                        show_type: res.data.show_type,
                        time_frame_data: res.data.time_frame_data,
                        strategy_type: res.data.strategy_type,
                    }
                    if (res.data.budget === 0) {
                        this.ruleForm.dailyBudget = 0
                    } else {
                        this.ruleForm.dailyBudget = 1
                    }
                
                    this.changeStuOpProResourcesAndPeopleList()
                  
                })
            },
            // 添加资源位
            addResourceBtn() {
                this.$refs.addResourceDrawer.addResourceDrawer = true
            
            },
            // 切换地域设置
            toggleLocation(val) {
                if (val === 1) {
                    this.ruleForm.locationNumber = this.areaValue.length
                    this.ruleForm.position_data = []
                }
            },
            // 打开特定地区抽屉
            openAreaDrawer() {
                this.$refs.areaSetDrawer.areaSetDrawer = true
            },
            // 删除资源位
            delResourceBtn() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请选择要删除的资源位!')
                }
                this.$confirm(`您确定要删除选中的${this.selectArr.length}个资源吗？`,'操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(()=>{
                    if(this.ruleForm.resources_data.length==this.selectArr.length){
                        this.ruleForm.resources_data=[]
                        this.$refs.addResourceDrawer.openDrawer()

                    }else{
                        for(let i = 0; i < this.ruleForm.resources_data.length; i++){
                        for(let j = 0; j < this.selectArr.length; j++){
                            if(this.ruleForm.resources_data[i].type === this.selectArr[j].type){
                                this.ruleForm.resources_data.splice(i,1)
                              this.$refs.addResourceDrawer.openDrawer()

                                
                            }
                        }
                    }
                    }
                    
                    this.$message.success('删除成功!')
                    
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            // 选择删除资源位的list
            selectChange(selection) {
                this.selectArr = selection
            },
            // 获取人群定向列表
            getPeopleItem(row, index) {
                // let params = {
                //     op_id: this.$route.query.op_id,
                //     offer_price: this.ruleForm.cast_fee,
                //     type: row.type,
                //     percent: row.percent,
                // }
                let params = {
                    op_id: this.$route.query.op_id,
                    cast_fee: this.ruleForm.cast_fee,
                    type: row.type,
                    percent: row.percent,
                    time_frame_data:this.infoStepTwoRuleForm.time_frame_data,
                    strategy_type:this.infoStepTwoRuleForm.strategy_type,
                    budget:this.infoStepTwoRuleForm.budget
                }
               
                // StuOpProGetPeopleData(params).then((res) => {
                    StuOpProPeopleData(params).then((res) => {
                    this.ruleForm.people_data[index].click = res.data.click
                    this.ruleForm.people_data[index].conversion = res.data.conversion
                    this.ruleForm.people_data[index].show_people = res.data.customers_click
                    this.ruleForm.people_data[index].show_total = res.data.customers
                }).catch((err) => {})
            },
            // 修改通投价格
            changeCastFee(event) {
                this.ruleForm.cast_fee = event
                this.ruleForm.people_data = this.ruleForm.people_data.map(item => {
                    return {
                        type: item.type,
                        percent: 100,
                        click: null,
                        conversion: null,
                        show_people: null,
                        show_total: null,
                        name: item.name,
                        switchPeople: false,
                    }
                })
            },
            // 人群定向 是否启动人群
            changeSwitchPeople(event, row, index) {
                if (this.ruleForm.cast_fee === void 0) {
                    return this.$message.warning('请先设置通投出价')
                }
                row.switchPeople = event
                if (event) {
                    this.getPeopleItem(row, index)
                } else {
                    this.ruleForm.people_data[index].click = null
                    this.ruleForm.people_data[index].conversion = null
                    this.ruleForm.people_data[index].percent = 100
                    this.ruleForm.people_data[index].show_people = null
                    this.ruleForm.people_data[index].show_total = null
                }
            },
            // 人群定向 溢价系数
            changePercent(row, index) {
                this.getPeopleItem(row, index)
            },

            // 返回
            goBack() {
                localStorage.removeItem('infoStepTwo')
                this.$router.push({
                    path: '/student/infographicPromotion/list',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                    }
                })
            },
            // 下一步
            toNext() {
                if (!this.ruleForm.unit_name) {
                    return this.$message.warning('请输入单元名称')
                }
                if (!this.ruleForm.cast_fee) {
                    return this.$message.warning('请输入通投出价')
                }
                if (this.ruleForm.resources_data.length === 0) {
                    return this.$message.warning('请添加资源位')
                }
                if (!this.selectPeople) {
                    return this.$message.warning('请至少选择一个人群定向')
                }
                let infoStepTwo = null
                if (localStorage.getItem('infoStepTwo')) {
                    infoStepTwo = JSON.parse(localStorage.getItem('infoStepTwo'))
                } else {
                    infoStepTwo = {
                        CPM: "CPM",
                        title: this.ruleForm.title,
                        budget: this.ruleForm.budget,
                        show_type: this.ruleForm.show_type,
                        time_frame_data: this.ruleForm.time_frame_data,
                        strategy_type: this.ruleForm.strategy_type,
                        dailyBudget: this.ruleForm.dailyBudget,
                    }
                }

                let params = {
                    title: infoStepTwo.title,
                    time_frame_data: infoStepTwo.time_frame_data,
                    strategy_type: infoStepTwo.strategy_type,
                    show_type: infoStepTwo.show_type,

                    unit_name: this.ruleForm.unit_name,
                    cast_fee: this.ruleForm.cast_fee,
                    resources_data: this.ruleForm.resources_data,
                    people_data: this.ruleForm.people_data,
                    op_id: this.$route.query.op_id,
                    location_type: this.ruleForm.location_type,
                }
                if (infoStepTwo.dailyBudget === 0) {
                    params.budget = 0
                } else {
                    params.budget = infoStepTwo.budget
                }
                if (this.ruleForm.location_type === 2) {
                    params.position_data = this.ruleForm.position_data
                }
                // 训练
                if (this.$route.query.course_id) {
                    params.course_id = this.$route.query.course_id
                }
                if (this.$route.query.editId) {
                    params.id = this.$route.query.editId
                }

                StuOpProSavePlan(params).then((res) => {
                    this.$message.success(res.msg)
                    this.$router.push({
                        path: '/student/infographicPromotion/stepFour',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: res.data
                        }
                    })
                    // localStorage.removeItem('infoStepTwo')
                }).catch((err) => {})
            },
            // 创建单元-资源位和定向推荐人群列表  在编辑和修改的时候要进行替换
            changeStuOpProResourcesAndPeopleList(){
             
                if(!this.ruleForm.cast_fee && this.ruleForm.resources_data.length==0) return 
                let people_data=[]
                let resources_data=[]
                this.ruleForm.people_data.forEach(item=>{
                    if(item.switchPeople){
                        people_data.push({
                            type: item.type,
                            percent: item.percent,
                        }) 
                    }
                })
                this.ruleForm.resources_data.forEach(item=>{
                    resources_data.push({
                        type:item.type
                    })
                })
            
                let params={
                    time_frame_data:this.infoStepTwoRuleForm.time_frame_data,
                    strategy_type:this.infoStepTwoRuleForm.strategy_type,
                    cast_fee: this.ruleForm.cast_fee || 0,
                    op_id: this.$route.query.op_id,
                    budget:this.infoStepTwoRuleForm.budget,
                    people_data:people_data,
                    resources_data:resources_data
                }
                StuOpProResourcesAndPeopleList(params).then(res=>{
                    if(res.data.people_data.length>0){
                        res.data.people_data.forEach(item=>{                         
                            const foundIndex=this.ruleForm.people_data.findIndex(item2=> {
                                return item2.type===item.type
                            } )
                           if(foundIndex !=-1){
                            // this.ruleForm.people_data.splice(foundIndex,1)
                            this.ruleForm.people_data.splice(foundIndex,1,item)
                           }
                        })
                    }
                
                    if(res.data.resources_data.length>0){
                        this.ruleForm.resources_data=res.data.resources_data;
                    }
                // console.log(this.ruleForm.people_data,'pp');
                })
            }
            

        }
    }
</script>

<style scoped lang="scss">
    .step-content {
        background: #fff;
        padding: 20px 30px 30px;
        margin-bottom: 20px;
    }
    .step-content-header {
        font-weight: 500;
        .big {
            font-size: 28px;
        }
    }
    .line-form {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 50px;
        .line-left {
            width: 130px;
            margin-right: 20px;
            text-align: right;
            font-weight: 500;
        }
        .line-right {
            width: 1%;
            flex: 1;
            display: flex;
            align-items: center;
        }
    }
    .resource-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .prompt-icon {
        color: #D7D7D7;
        cursor: pointer;
        margin: 0 10px;
    }
    .directional-set-wrapper {
        width: 100%;
        padding: 20px;
        border: 1px solid rgba(151, 151, 151, 0.3);
        .line-item {
            margin-bottom: 20px;
        }
        .directional-header-table {
            width: 100%;
            line-height: 50px;
            padding: 0 20px;
            background: #F5F5F5;
            box-sizing: border-box
        }
    }
    .red {
        color: #FD4446;
    }
</style>